/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import clsx from "clsx";
import "./index.scss";
import useComponentDidMount from "./hooks/useComponentDidMount";
import {
  ServiceSearchProvider,
  useServiceSearchContext,
  Actions
} from "./state/service-search.context";
// @import custom components
import SearchServicesPage from "./pages/search-services/SearchServicesPage";
import SearchServicesModalHeader from "./components/search-services-modal-header/SearchServicesModalHeader";
import CustomerOperationPage from "./pages/custom-operation/CustomerOperationPage";
import CustomOperationFooter from "./components/custom-operation-footer/CustomOperationFooter";
// @import services

// @import utils
import serviceDataMapper from "./utils/service-data-mapper";
import { removeTokenCache } from "./pages/dealer-tire/dealer-tire-util";
import { alphabeticalOrder } from "./utils/sorting-alphabetical.util";
import { getLocales } from "./i18n/locales";
import modalPages from "./constants/pages.constants";
import { quickFilterPages } from "./constants/quick-filters.constants";
import {
  QuoteServiceTypeMap,
  saveActionTypes
} from "./constants/search.constants";
import { DEFAULT_APP_CONFIG } from "./constants/search-config.constants";
import { filterServiceContracts } from "./utils/service-contracts.util";

/**
 * @param {Element} EditServiceModule Custom Service Module Component
 * @param {object} config Global Config object {isDebug, webKey, dealer, user, schemaName}
 * @param {string} locale Define locale value en_US to render translated strings
 * @param {object} vehicle Gets optional Vehicle object used to read vin, drivingConditions
 * @param {array} synonymsList List of Synomyms to match service level categoryId
 * @param {object} makeVariantMap optional makeVariantMap used to get laborPrecision for dealer unsupported make
 * @param {array} quoteServices Array of Quote Services
 * @param {array} servicePoints Service Points array of Objects
 * @param {array} globalOperations List of Global Operations
 * @param {array} topServices List of Top Services
 * @param {object} declinedAndRecallServices List of Declined and Recall Services wrapped in object
 * @param {function} onCloseModal Close modal action Handler
 * @param {function} onSelectedMenu Selected Menu action Handler
 * @param {function} onSelectedDeclinedService Selected Declined Service action Handler
 * @param {function} onSelectedGlobalOpsService Selected Global Operations Service action Handler
 * @param {function} onSelectedRecallService Selected Recall Service action Handler
 * @param {function} onGetOperationDetails Callback rest API handler to fetch Operation Details when service is selected
 * @param {function} onGetDealerTireConfig Callback rest API handler to fetch Dealer Tire Configuration from DealerTire API
 * @param {function} onGetServicePoints Callback rest API handler to fetch MENU for selected Mileage point
 * @param {function} onEditService Edit Service Action Callback
 * @returns {Element}
 */
const ServiceSearchModule = forwardRef((props, ref) => {
  const {
    EditServiceModule,
    dealerTireParams,
    quoteServices,
    onCloseModal,
    config,
    synonymsList,
    vehicle,
    locale,
    makeVariantMap,
    servicePoints,
    globalOperations,
    topServices,
    declinedAndRecallServices,
    onSelectedMenu,
    onSelectedDeclinedService,
    onSelectedGlobalOpsService,
    onSelectedRecallService,
    payTypes,
    serviceTypes,
    vendorList,
    serviceContracts,
    handleGoBackToCSRQuoteSummary
  } = props;
  const { state, dispatch } = useServiceSearchContext();
  const {
    closeModal,
    searchHeader,
    isMenuUpdated,
    selectedMenuPackage,
    selectedService,
    selectedDeclinedService,
    selectedGlobalOpsService,
    selectedAlacarteService,
    selectedRecallService,
    currentPage,
    saveActionType,
    showPageMask
  } = state;
  useImperativeHandle(ref, () => ({
    receiveGlobalOperationDetails: globalOperationsDetails => {
      dispatch({
        type: Actions.SET_GLOBAL_OPERATION_DETAILS,
        payload: globalOperationsDetails
      });
    },
    receiveDealerTireConfig: dealerTireAuth => {
      dispatch({
        type: Actions.SET_DEALER_TIRE_AUTH,
        payload: dealerTireAuth
      });
    },
    receiveServicePoints: servicePoints => {
      dispatch({
        type: Actions.SET_SERVICE_POINTS,
        payload: servicePoints
      });
    },
    receivePartsPricingAndInventory: partsPricingAndInventory => {
      dispatch({
        type: Actions.SET_PARTS_PRICING_INVENTORY,
        payload: partsPricingAndInventory
      });
    }
  }));
  // @note: didmount called once when service search page is loaded
  // inside comp didmount, set actions which will read values only once
  useComponentDidMount(() => {
    removeTokenCache("dealertire-token");
    dispatch({
      type: Actions.SET_APP_CONFIG,
      payload: config
    });
    dispatch({
      type: Actions.SET_LOCALE,
      payload: locale
    });
    dispatch({
      type: Actions.SET_DEALER_TIRE_PARAMS,
      payload: dealerTireParams
    });
    dispatch({
      type: Actions.SET_LOCALE_STRINGS,
      payload: getLocales(locale)
    });
    dispatch({
      type: Actions.SET_MAKE_VARIANT_MAP,
      payload: makeVariantMap
    });
    dispatch({
      type: Actions.RESET_NAVIGATION
    });
    dispatch({
      type: Actions.SET_GLOBAL_SYNONYMS,
      payload: synonymsList
    });
    dispatch({
      type: Actions.SET_DEBUG_MODE,
      payload: config.isDebug
    });
    // @csr-logic
    dispatch({
      type: Actions.SET_APP_TYPE,
      payload: config.appType
    });
    dispatch({
      type: Actions.SET_USER_PERMISSIONS,
      payload: config.userPermissions
    });
    if (closeModal) {
      onCloseModal();
    }
  }); // end of didmount

  useEffect(() => {
    dispatch({
      type: Actions.SET_PAY_TYPES,
      payload: payTypes
    });
  }, [payTypes]);

  useEffect(() => {
    dispatch({
      type: Actions.SET_SERVICE_TYPES,
      payload: serviceTypes
    });
  }, [serviceTypes]);

  useEffect(() => {
    dispatch({
      type: Actions.SET_VENDOR_LIST,
      payload: vendorList
    });
  }, [vendorList]);

  useEffect(() => {
    dispatch({
      type: Actions.SET_SERVICE_CONTRACTS,
      payload: filterServiceContracts(serviceContracts)
    });
  }, [serviceContracts]);

  useComponentDidMount(() => {
    let defaultSelectedDrivingCondition = "Normal";
    if (!isEmpty(vehicle)) {
      if (vehicle.drivingConditions && vehicle.drivingConditions.length === 1) {
        defaultSelectedDrivingCondition = vehicle.drivingConditions[0].value;
      } else if (
        vehicle.drivingConditions &&
        vehicle.drivingConditions.length > 1
      ) {
        defaultSelectedDrivingCondition = !vehicle.defaultDrivingCondition
          ? "Normal"
          : vehicle.defaultDrivingCondition;
      }
    }
    // @note: top services, global services, declined all data should load without vehicle check
    const asyncServiceBundle = async () => {
      const promiseData = await loadServicesBundle();
      if (promiseData) {
        togglePageMasking(false);
      }
    };
    asyncServiceBundle();
    dispatch({
      type: Actions.SET_VEHICLE,
      payload: vehicle
    });
    dispatch({
      type: Actions.SET_VEHICLE_DEFAULT_DRIVING_CONDITION,
      payload: defaultSelectedDrivingCondition
    });
  }); // end of didmount

  // @note: Main logic to read selected service and pass to prop handler, then service is saved in quote via its Search wrapper
  useEffect(() => {
    // @todo: wait for proper actionType {SAVE or SAVEANOTHER} before passing saveActionType argu to all service select handlers only
    if (!isEmpty(saveActionType)) {
      // togglePageMasking(false);
      if (saveActionType === saveActionTypes.SAVEANOTHER) {
        console.log(
          "[SaveActionType] value to this wrapper handler()",
          saveActionType
        );
        dispatch({
          type: Actions.RESET_SEARCH_MODULE
        });
      }
      if (!isEmpty(selectedMenuPackage) && isMenuUpdated) {
        onSelectedMenu(selectedMenuPackage, saveActionType);
        dispatch({
          type: Actions.REMOVE_SELECTED_MENU_PACKAGE
        });
        dispatch({
          type: Actions.IS_MENU_UPDATED,
          payload: false
        });
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: null
        });
      }
      if (!isEmpty(selectedDeclinedService)) {
        onSelectedDeclinedService(selectedDeclinedService, saveActionType);
        dispatch({
          type: Actions.REMOVE_SELECTED_DECLINED_SERVICE
        });
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: null
        });
      }

      // @note: read record from top serivce, diagnose, global search service and pass to new quote context
      if (!isEmpty(selectedGlobalOpsService)) {
        onSelectedGlobalOpsService(selectedGlobalOpsService, saveActionType);
        dispatch({
          type: Actions.REMOVE_SELECTED_GLOBAL_OPS_SERVICE
        });
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: null
        });
      }
      if (!isEmpty(selectedRecallService)) {
        onSelectedRecallService(selectedRecallService, saveActionType);
        dispatch({
          type: Actions.SET_SELECTED_RECALL_SERVICE,
          payload: null
        });
        dispatch({
          type: Actions.SET_SAVE_ACTION_TYPE,
          payload: null
        });
      }
    }
  }, [
    dispatch,
    isMenuUpdated,
    saveActionType,
    selectedMenuPackage,
    selectedService,
    selectedDeclinedService,
    selectedGlobalOpsService,
    selectedAlacarteService,
    selectedRecallService,
    onSelectedDeclinedService,
    onSelectedGlobalOpsService,
    onSelectedMenu,
    onSelectedRecallService
  ]);
  // Local handler to enable masking effect by updating newQuoteContext state prop
  const togglePageMasking = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  const loadServicesBundle = async () => {
    togglePageMasking(true);
    try {
      const allOperations = serviceDataMapper.globalOperationMapper(
        globalOperations,
        synonymsList
      );
      const diagnosisServices =
        serviceDataMapper.diagnosisServicesMapper(allOperations);
      alphabeticalOrder(diagnosisServices);
      const formattedTopServices = serviceDataMapper.topServicesMapper(
        topServices,
        synonymsList
      );
      // @note: recall services optional to search module
      let recallServices = [];
      let declinedServices = [];
      if (!isEmpty(declinedAndRecallServices)) {
        recallServices = filterRecallsByStatus(
          declinedAndRecallServices.recallServices,
          "OPEN"
        );
        recallServices = serviceDataMapper.recallServiceMapper(recallServices);
        declinedServices = serviceDataMapper.declinedServicesMapper(
          declinedAndRecallServices.declinedServices,
          synonymsList
        );
        console.log(
          "declined/recalled services",
          declinedServices,
          recallServices
        );
      }
      alphabeticalOrder(recallServices);
      alphabeticalOrder(declinedServices);

      const filterRecallServices = [];
      if (!isEmpty(recallServices)) {
        recallServices.forEach(service => {
          const isServiceExist = findService(quoteServices, service.recordId);
          if (!isServiceExist) {
            const recallServiceName = "RECALL";
            service.source = recallServiceName;
            filterRecallServices.push(service);
          }
        });
      }
      dispatch({
        type: Actions.SET_SERVICE_POINTS,
        payload: [...servicePoints]
      });
      dispatch({
        type: Actions.SET_ALL_OPERATIONS,
        payload: [...allOperations]
      });
      dispatch({
        type: Actions.SET_SERVICES_FOR_SEARCH,
        payload: [...allOperations]
      });
      dispatch({
        type: Actions.SET_DIAGNOSIS_SERVICES,
        payload: [...diagnosisServices]
      });
      dispatch({
        type: Actions.SET_TOP_SERVICES,
        payload: [...formattedTopServices]
      });
      dispatch({
        type: Actions.SET_DECLINED_SERVICES,
        payload: [...declinedServices]
      });
      dispatch({
        type: Actions.SET_RECALL_SERVICES,
        payload: [...filterRecallServices]
      });
      return true;
    } catch (error) {
      const msg = !error ? "Error occured in search module" : error.message;
      console.error(msg);
      return null;
    } finally {
      togglePageMasking(false);
    }
  };

  const findService = (services, serviceId) => {
    const matches = services.filter(service => {
      return !service.extServiceId
        ? String(service.id) === String(serviceId)
        : service.extServiceId === serviceId.toString();
    });
    return matches.length !== 0;
  };

  const filterRecallsByStatus = (recallServices, status) => {
    return recallServices.filter(service => {
      return service.status === status;
    });
  };

  const handleAddCustomOperationClick = useCallback(() => {
    // @todo Handle
    if (!config.isDebug) return;
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: modalPages.CUSTOM_OPERATION
    });
    dispatch({
      type: Actions.SET_CHANGE_SEARCH_QUERY,
      payload: ""
    });
    dispatch({
      type: Actions.SET_SERVICE_FILTER_BY,
      payload: ""
    });
    dispatch({
      type: Actions.SET_CURRENT_QUICK_FILTER_PAGE,
      payload: quickFilterPages.TOP_SERVICES
    });
  }, [dispatch, config.isDebug]);

  const searchPage = !isEmpty(state.appConfig) ? (
    <SearchServicesPage EditServiceModule={EditServiceModule} />
  ) : null;

  const pagesMap = {
    [modalPages.SEARCH_SERVICES]: searchPage,
    [modalPages.CUSTOM_OPERATION]: (
      <CustomerOperationPage EditServiceModule={EditServiceModule} />
    )
  };
  // @change: Avoid duplicate loadmask when saving menu/service from edit pages
  const pageContent = showPageMask ? null : pagesMap[currentPage];

  const compCls = searchHeader ? "empty-cls" : "hide-ele";
  const searchCls = clsx(
    compCls,
    "search-sticky",
    state?.appType === "CSR" ? "search-sticky-extra" : ""
  );

  return (
    <div id="ServiceSearchModal">
      {/* //* button to go back to summary screen for quote from search service */}
      {config?.appType === "CSR" && searchHeader ? (
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleGoBackToCSRQuoteSummary}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          Back
        </span>
      ) : null}
      <div className={searchCls}>
        {currentPage === modalPages.SEARCH_SERVICES && !showPageMask ? (
          <SearchServicesModalHeader />
        ) : null}
      </div>
      {pageContent}
      <div className={compCls}>
        {currentPage === modalPages.SEARCH_SERVICES && !showPageMask ? (
          <CustomOperationFooter
            onAddCustomOperationClick={handleAddCustomOperationClick}
          />
        ) : null}
      </div>
    </div>
  );
});

ServiceSearchModule.displayName = "ServiceSearchModule";

const ServiceSearchModuleWrapper = props => {
  const {
    onGetOperationDetails,
    onGetDealerTireConfig,
    onGetServicePoints,
    onEditService,
    onGetPartsPricingAndInventory,
    onGetPartsInventoryCommon,
    gtmEvent: ctxGtmEvent,
    vehicle
  } = props;
  // const { state, dispatch } = useServiceSearchContext(); // unused
  const serviceSearchModuleRef = useRef();
  // util to return default paytype parse thru unified response
  const getDefaultPayType = (operationSource, payType) => {
    const tempPayType = !payType ? "" : payType;
    let payTypeCode =
      operationSource === "RECALL"
        ? "W"
        : operationSource === "DECLINED"
        ? "C"
        : tempPayType;
    if (!isEmpty(vehicle?.stockNumber)) {
      payTypeCode = "I";
    }
    return payTypeCode;
  };
  // util to return default serviceType parse thru unified response
  const getDefaultServiceType = serviceType => {
    return serviceType ? serviceType : "";
  };

  const handleRequestOperationDetails = service => {
    onGetOperationDetails(
      {
        operationName: service.serviceName || service.rawRecord.operationName,
        operationSource: service.rawRecord.operationSource,
        operationId: service.rawRecord.operationId
      },
      globalOperationDetails => {
        serviceSearchModuleRef.current.receiveGlobalOperationDetails(
          globalOperationDetails
        );
        // @workaround: uncomment this - call partsInventory api when globalOperationDetails response is ready
        const payTypeCode = getDefaultPayType(
          globalOperationDetails.serviceKind,
          globalOperationDetails.defaultPayTypeCode
        );
        const serviceTypeCode = getDefaultServiceType(
          globalOperationDetails.defaultServiceType
        );
        handleRequestPartsPricingAndInventory(
          payTypeCode,
          serviceTypeCode,
          service,
          globalOperationDetails
        );
      }
    );
  };
  // @todo: new handler to callback partsPriceInventory api called from handleRequestOperationDetails()
  const handleRequestPartsPricingAndInventory = (
    payType,
    serviceType,
    service,
    globalOperationDetails
  ) => {
    const params = {
      operationName: service.serviceName || service.rawRecord.operationName,
      operationSource: service.rawRecord.operationSource,
      operationId: service.rawRecord.operationId,
      payType,
      serviceType
    };
    // @note: if this second callback not refreshing with globalOperations object, we update partsPricingAndInventory to SearchContext
    onGetPartsPricingAndInventory(
      params,
      globalOperationDetails,
      partsPricingAndInventory => {
        serviceSearchModuleRef.current.receivePartsPricingAndInventory(
          partsPricingAndInventory
        );
      }
    );
  };
  // Extracting individual parts from parts module to add to the service level parrts
  const extractIndividualParts = (serviceParts, editedServiceParts) => {
    const partNumberlist = [];
    const individualParts = [];
    serviceParts.forEach(part => partNumberlist.push(part.oemPartNumber));

    editedServiceParts.forEach(customPart => {
      if (!partNumberlist.includes(customPart.oemPartNumber)) {
        individualParts.push(customPart);
      }
    });
    return individualParts;
  };
  // This handler is meant to be called to get open track api data for non-global ops parts such as menus
  const handleRequestPartsInventoryCommon = (service, editedService) => {
    let editServiceParts = [];
    const payType = !isEmpty(service.payTypeCode)
      ? service.payTypeCode
      : !isEmpty(service.defaultPayTypeCode)
      ? service.defaultPayTypeCode
      : "";
    const serviceTypeCode = !isEmpty(service.serviceTypeCode)
      ? service.serviceTypeCode
      : !isEmpty(service.defaultServiceTypeCode)
      ? service.defaultServiceTypeCode
      : "";
    service.id = service.id || service.operationId;
    // This logic to check whether parts is read from service or laborApps for all menus, service cases
    let parts = [];
    if (service.operationSource === QuoteServiceTypeMap.MENU) {
      parts =
        has(service, "parts") && !isEmpty(service.parts)
          ? service.parts
          : has(service, "part") && !isEmpty(service.part)
          ? service.part
          : [];
    }
    if (isEmpty(parts) && has(service, "laborApps")) {
      parts = !isEmpty(service.laborApps[0].parts)
        ? service.laborApps[0].parts
        : [];
    }

    if (!isEmpty(editedService) && has(editedService, "parts")) {
      editServiceParts = extractIndividualParts(parts, editedService.parts);
    }
    const concatenatedParts = parts.concat(editServiceParts);

    service.parts = concatenatedParts;
    onGetPartsInventoryCommon(
      service,
      payType,
      partsPricingAndInventory => {
        serviceSearchModuleRef.current.receivePartsPricingAndInventory(
          partsPricingAndInventory
        );
      },
      serviceTypeCode
    );
  };
  const handleRequestServicePoints = params => {
    onGetServicePoints(
      {
        drivingCondition: params.drivingCondition,
        locale: params.locale,
        mileage: params.mileage,
        useExactMileage: params.useExactMileage
      },
      servicePoints => {
        serviceSearchModuleRef.current.receiveServicePoints(servicePoints);
      }
    );
  };
  const handleOnEditService = isEditing => {
    onEditService(isEditing);
  };
  const handleDealerTireConfig = () => {
    onGetDealerTireConfig(dealerTireAuth => {
      serviceSearchModuleRef.current.receiveDealerTireConfig(dealerTireAuth);
    });
  };
  return (
    <ServiceSearchProvider
      ctxRequestOperationDetails={handleRequestOperationDetails}
      ctxRequestDealerTireConfig={handleDealerTireConfig}
      ctxRequestServicePoints={handleRequestServicePoints}
      ctxOnEditService={handleOnEditService}
      ctxRequestPartsPricingAndInventory={handleRequestPartsPricingAndInventory}
      ctxRequestPartsInventoryCommon={handleRequestPartsInventoryCommon}
      ctxGtmEvent={ctxGtmEvent}
    >
      <ServiceSearchModule {...props} ref={serviceSearchModuleRef} />
    </ServiceSearchProvider>
  );
};

ServiceSearchModuleWrapper.defaultProps = {
  locale: "en_US",
  EditServiceModule: null,
  config: DEFAULT_APP_CONFIG,
  axiosService: null,
  // data object props
  makeVariantMap: null,
  vehicle: null,
  synonymsList: [],
  globalOperations: [],
  topServices: [],
  servicePoints: [],
  declinedAndRecallServices: null,
  gtmEvent: {
    trackGAEvent: () => {},
    trackGAEventWithParam: () => {},
    trackGAError: () => {},
    trackGAUnknowError: () => {}
  },
  dealerTireParams: {},
  quoteServices: [],
  onCloseModal: () => {},
  onSelectedMenu: () => {},
  onSelectedDeclinedService: () => {},
  onSelectedGlobalOpsService: () => {},
  onSelectedRecallService: () => {},
  onGetOperationDetails: () => {},
  onGetPartsPricingAndInventory: () => {},
  onGetServicePoints: () => {},
  onEditService: () => {},
  onGetPartsInventoryCommon: () => {},
  handleGoBackToCSRQuoteSummary: () => {}
};

ServiceSearchModuleWrapper.propTypes = {
  // @required props
  locale: PropTypes.string.isRequired,
  config: PropTypes.shape({
    isDebug: PropTypes.bool,
    appType: PropTypes.string,
    userPermissions: PropTypes.object,
    isDealerTireEnabled: PropTypes.bool,
    webKey: PropTypes.string,
    dealer: PropTypes.object,
    schemaName: PropTypes.string,
    user: PropTypes.shape({
      userName: PropTypes.string
    }),
    customOperationPage: PropTypes.bool,
    quickFilterAccess: PropTypes.shape({
      topServices: PropTypes.bool,
      menu: PropTypes.bool,
      diagnosis: PropTypes.bool,
      declined: PropTypes.bool,
      recall: PropTypes.bool,
      tires: PropTypes.bool
    }),
    editModuleAccess: PropTypes.bool,
    showPrice: PropTypes.bool,
    showOpcode: PropTypes.bool,
    showQuickFilters: PropTypes.bool
  }).isRequired,
  vehicle: PropTypes.object,
  synonymsList: PropTypes.array,
  // optional props
  quoteServices: PropTypes.array,
  makeVariantMap: PropTypes.object,
  dealerTireParams: PropTypes.object,
  // data props
  topServices: PropTypes.array.isRequired,
  globalOperations: PropTypes.array.isRequired,
  servicePoints: PropTypes.array,
  declinedAndRecallServices: PropTypes.object,
  gtmEvent: PropTypes.shape({
    trackGAEventWithParam: PropTypes.func,
    trackGAEvent: PropTypes.func,
    trackGAError: PropTypes.func,
    trackGAUnknowError: PropTypes.func
  }),
  // @Exceptional case: axios instance passed to call rest api's inside partsLookup or EditService modules for dynamic data callbacks
  axiosService: PropTypes.object,
  // module React element
  EditServiceModule: PropTypes.element,
  // event handlers
  onCloseModal: PropTypes.func.isRequired,
  onSelectedMenu: PropTypes.func.isRequired,
  onSelectedDeclinedService: PropTypes.func.isRequired,
  onSelectedGlobalOpsService: PropTypes.func.isRequired,
  onSelectedRecallService: PropTypes.func.isRequired,
  // callback restAPI handlers to fetch details
  onGetDealerTireConfig: PropTypes.func,
  onGetOperationDetails: PropTypes.func.isRequired,
  onGetPartsPricingAndInventory: PropTypes.func,
  onGetServicePoints: PropTypes.func,
  onEditService: PropTypes.func,
  onGetPartsInventoryCommon: PropTypes.func,
  //* function for go back to summary screen for quote from search service
  handleGoBackToCSRQuoteSummary: PropTypes.func
};

export default memo(ServiceSearchModuleWrapper);
