/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
// WIP remove lint exceptions once component is completed ******
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import clsx from "clsx";
import Button from "@cx/ui/Button";
import ModalDialogWithHeader from "./service-contracts-form.component";
import {
  formatNumberWithThousands,
  priceValueFormatter
} from "../../utils/formatter.util";
import uniqueId from "lodash/uniqueId";
import { AppContext } from "../../state/app-context";
import {
  filterServiceContracts,
  formatVendorProduct,
  getServiceContractsInfo$
} from "./utils/service-contracts.util";
import { formatDateString } from "../utils/format";
import isNumber from "lodash/isNumber";

export const AddServiceContract = ({
  isModal = false,
  vin,
  commonConsumerId,
  serviceContracts = []
}) => {
  const appContext = useContext(AppContext);
  const { dealer } = appContext;

  const partsGridCls = clsx(
    "ag-grid-container",
    "ops-auto-height",
    "ag-theme-balham",
    "csr-tech-height"
  );
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [rowData, setRowData] = useState(serviceContracts);

  const [showServiceContractForm, setShowServiceContractForm] = useState(false);

  const columnDefs = useMemo(
    () => [
      {
        headerName: "Service Contracts",
        field: "vendorName",
        cellRenderer: "agGroupCellRenderer",
        width: 450,
        valueFormatter: params =>
          formatVendorProduct(params?.value, params?.data?.productName)
      },
      { headerName: "Contract Number", field: "contractNumber" },
      {
        headerName: "Expiration Date",
        field: "expirationDate",
        valueFormatter: params =>
          params.value ? formatDateString(new Date(params.value)) : ""
      },
      {
        headerName: "Expiration Miles",
        field: "expirationMileage",
        valueFormatter: params =>
          isNumber(params.value) ? formatNumberWithThousands(params.value) : ""
      },
      {
        headerName: "Deductible",
        field: "deductible",
        valueFormatter: params =>
          isNumber(params.value) ? priceValueFormatter(params.value) : ""
      }
    ],
    []
  );

  const defaultColDef = useMemo(() => ({ flex: 1 }), []);

  const onGridReady = useCallback(params => {
    params.api.sizeColumnsToFit();
  }, []);

  const detailCellRendererParams = useMemo(
    () => ({
      getDetailRowData: params => {
        params.successCallback(params.data);
      },
      showRemove: true,
      disabledCancel: true,
      disabledSave: true,
      isNewRecord: false,
      onRemove: id =>
        setRowData(prevRowData => prevRowData.filter(item => item.id !== id)),
      isModal
    }),
    []
  );

  const onFirstDataRendered = useCallback(params => {
    // params.api.forEachNode(node => node.setExpanded(node.id === "1"));
  }, []);

  const gridOptions = useMemo(
    () => ({
      suppressRowClickSelection: true,
      enableCellChangeFlash: false,
      detailRowHeight: 286, // change value to 424 once buttons are added back to the form
      getContextMenuItems: () => []
    }),
    []
  );

  const onCancel = () => setShowServiceContractForm(false);
  const onSave = data => {
    const newData = { ...data, id: uniqueId("serviceContract_") };
    setRowData(prevRowData => [...prevRowData, newData]);
    setShowServiceContractForm(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      const serviceContractList = await getServiceContractsInfo$(
        dealer.dealerCode,
        vin,
        commonConsumerId,
        dealer.schemaName
      );
      setRowData(filterServiceContracts(serviceContractList));
    };

    if (!isModal && dealer?.dealerCode) {
      fetchData().then();
    }
  }, [dealer, isModal]);

  return (
    <div
      className={`service-contract-content ${
        isModal ? "service-contract-content--modal" : ""
      }`}
    >
      <div style={containerStyle}>
        {!isModal ? <h3>Service Contracts</h3> : null}
        <div style={gridStyle} className="ag-theme-quartz-dark">
          <div id="partsGrid" className={partsGridCls}>
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              masterDetail={true}
              detailCellRenderer="detailCellRenderer"
              frameworkComponents={{
                detailCellRenderer: ModalDialogWithHeader
              }}
              detailCellRendererParams={detailCellRendererParams}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </div>
        {/* uncomment lines below when buttons are added back to the form */}
        {/* <Button
          style={{ marginTop: 10 }}
          buttonStyle="secondary"
          size="sm"
          htmlId="btnSecondary"
          disabled={showServiceContractForm}
          onClick={() => setShowServiceContractForm(true)}
        >
          Add a service contract
        </Button> */}
        {/* {showServiceContractForm && (
          <div style={{ marginTop: 10 }}>
            <ModalDialogWithHeader onCancel={onCancel} onSave={onSave} />
          </div>
        )} */}
      </div>
    </div>
  );
};
